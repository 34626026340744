<template>
  <div class="uk-container-expand">
    <div class="uk-container">
      <nav class="uk-navbar uk-navbar-container uk-hidden@m uk-background-muted uk-position-fixed uk-position-top uk-width-1-1 nav-mob">
        <div class="uk-navbar-left uk-margin-small-left">
          <a href="https://vevanta.com"
            ><img src="../assets/img/logo.png" width="150px"
          /></a>
        </div>
        <div class="uk-navbar-right">
          <a
            class="uk-navbar-toggle"
            uk-navbar-toggle-icon
            href="#mobile-nav"
            uk-toggle
          ></a>
        </div>
      </nav>

      <div class="uk-visible@m">
        <nav
          class="uk-margin-remove uk-padding-small nav-top "
          uk-navbar="mode: click"
        >
          <div class="uk-navbar-right">
            <ul class="uk-subnav uk-margin-remove-bottom">
              <li>
                <a
                  href="https://vevanta.com/zemelnye-uchastki"
                  target="_blank"
                  class="uk-padding-remove"
                  >Земельные участки</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/category/special"
                  target="_blank"
                  class="uk-padding-remove"
                  >Акции</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/vakansii"
                  target="_blank"
                  class="uk-padding-remove"
                  >Вакансии</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/o-kompanii"
                  target="_blank"
                  class="uk-padding-remove"
                  >О компании</a
                >
              </li>
              <li>
                <a
                  href="https://lk.vevanta.com/auth"
                  target="_blank"
                  class="uk-padding-remove"
                  >Личный кабинет</a
                >
              </li>
            </ul>
          </div>
        </nav>
        <div class="uk-container uk-margin-remove" uk-grid>
          <div class="uk-width-expand uk-padding-remove">
            <a href="https://vevanta.com"
              ><img src="../assets/img/logo.png"
            /></a>
          </div>
          <div class="uk-margin-auto-vertical">
            Г. Тюмень, ул. Червишевский тракт, д. 3<br />
            <b>Режим работы:</b><br />
            Пн-Сб 09:00-19:00
          </div>

          <div class="uk-margin-auto-vertical">
            <div class="uk-margin-small-bottom">
              <a class="green uk-text-bold" href="tel:+73452396571"
                >+7 (3452) 39-65-71</a
              >
            </div>
              <button class="btn btn__green btn__square" uk-toggle="target: #call-modal">
                  Заказать звонок
              </button>
          </div>
          <div
            class="uk-align-right uk-text-right uk-grid-column-small uk-margin-remove uk-width-1-6 logos"
          >
            <p class="uk-margin-small">
              <a
                href="https://vk.com/stroitelstvo_domov_i_kottedjey"
                target="_blank"
                ><img
                  src="../assets/img/vkontakte.png"
                  height="20px"
                  width="20px"
              /></a>
            </p>
            <p class="uk-margin-small">
              <a href="https://www.instagram.com/vevanta72" target="_blank"
                ><img
                  src="../assets/img/instagram.png"
                  height="20px"
                  width="20px"
              /></a>
            </p>
            <p class="uk-margin-small">
              <a
                href="https://www.youtube.com/channel/UCRytU0cHs-IZN0dsBZW6Shw"
                target="_blank"
                ><img src="../assets/img/youtube.png" height="20px" width="20px"
              /></a>
            </p>
          </div>
        </div>
        <nav uk-navbar="mode: click" class="uk-margin-medium-top">
          <div class="uk-navbar-left">
            <ul class="uk-navbar-nav nav-bottom">
              <li>
                <a
                  href="https://vevanta.com/"
                  target="_blank"
                  class="uk-padding-small"
                  >Услуги</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/proekty-zagorodnyh-domov"
                  target="_blank"
                  class="uk-padding-small"
                  >Каталог проектов</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/arhitekturnoe-proektirovanie-zhilyh-domov-chto-nuzhno-znat-zakazchiku"
                  target="_blank"
                  class="uk-padding-small"
                  >Заказать проект</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/category/otzyvy"
                  target="_blank"
                  class="uk-padding-small"
                  >Отзывы</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/category/tehnologii"
                  target="_blank"
                  class="uk-padding-small"
                  >Технологии</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/portfolio"
                  target="_blank"
                  class="uk-padding-small"
                  >Портфолио</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/lenta"
                  target="_blank"
                  class="uk-padding-small"
                  >Лента</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/category/video/houses-reviews"
                  target="_blank"
                  class="uk-padding-small"
                  >Цены</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/kontakty"
                  target="_blank"
                  class="uk-padding-small"
                  >Контакты</a
                >
              </li>
            </ul>
          </div>
        </nav>
      </div>

      <div id="mobile-nav" uk-offcanvas>
        <div class="uk-offcanvas-bar uk-background-default">
          <button class="uk-offcanvas-close" type="button" uk-close></button>
          <div class="uk-width-expand uk-padding-remove">
            <a href="https://vevanta.com"
              ><img src="../assets/img/logo.png"
            /></a>
            <p class="uk-margin-remove-top">Тюмень, Московский тракт, 165/1</p>
            <p>
              <span
                class="uk-margin-small-right green"
                uk-icon="receiver"
              ></span
              ><a class="green" href="tel:+7 (3452) 56-41-95"
                >+7 (3452) 56-41-95</a
              >
            </p>
            <ul class="uk-nav uk-nav-default uk-list uk-list-divider">
              <li>
                <a
                  href="https://vevanta.com/"
                  target="_blank"
                  class="uk-padding-small"
                  >Услуги</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/proekty-zagorodnyh-domov"
                  target="_blank"
                  class="uk-padding-small"
                  >Каталог проектов</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/arhitekturnoe-proektirovanie-zhilyh-domov-chto-nuzhno-znat-zakazchiku"
                  target="_blank"
                  class="uk-padding-small"
                  >Заказать проект</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/category/otzyvy"
                  target="_blank"
                  class="uk-padding-small"
                  >Отзывы</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/category/tehnologii"
                  target="_blank"
                  class="uk-padding-small"
                  >Технологии</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/portfolio"
                  target="_blank"
                  class="uk-padding-small"
                  >Портфолио</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/lenta"
                  target="_blank"
                  class="uk-padding-small"
                  >Лента</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/category/video/houses-reviews"
                  target="_blank"
                  class="uk-padding-small"
                  >Цены</a
                >
              </li>
              <li>
                <a
                  href="https://vevanta.com/kontakty"
                  target="_blank"
                  class="uk-padding-small"
                  >Контакты</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <modal-callback
      modal-id="call-modal"
      title="Заявка на звонок"
      btn-name="Отправить заявку"
      :staff-id="78"
      :source-id="50"
    />
  </div>
</template>

<script>
import ModalCallback from "./ModalCallback";

export default {
  name: 'header',
  components: {
    ModalCallback
  }
};
</script>

<style scoped>
.uk-subnav li a {
  font-size: 0.75em !important;
}
.uk-subnav > * > :first-child {
  color: #000 !important;
}
.nav-top {
  padding-right: 0 !important;
}

.nav-top a {
  font-size: 11px;
}

.uk-navbar-nav.nav-bottom li:first-child a {
  padding-left: 0 !important;
}

.uk-navbar-nav.nav-bottom a {
  font-weight: bold;
}

.logos a {
  opacity: 0.9;
  filter: grayscale(1);
  transition: filter 0.3s, opacity 0.3s;
}

#mobile-nav p,
#mobile-nav li,
#mobile-nav a,
hr {
  color: #666;
  border-color: #666;
}

.nav-mob {
  position: absolute;
  top: 0px;
  z-index: 4;
}
</style>
