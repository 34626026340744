<template>
  <div
    class="ipoteka-container uk-position-relative uk-flex uk-flex-center uk-flex-middle"
  >
    <div
      class="ipoteka-container__info uk-flex uk-flex-wrap uk-position-relative"
    >
      <div class="uk-width-1-1 uk-width-3-5@m uk-flex uk-flex-column">
        <div class="uk-flex uk-flex-wrap info__top">
          <div class="info__content">
            <p class="fs34 uk-margin-remove uk-text-bold lh1">
              Ипотека от 8,5%
            </p>
            <p class="fs20 uk-margin-remove">
              на приобретение земельного участка и строительства на нем
            </p>
            <p class="fs16">Работаем с ведущими банками</p>
            <div class="uk-flex uk-flex-wrap info__banks">
              <div>
                <img
                  class="uk-width-auto"
                  src="https://vevanta.ru/wp-content/uploads/2020/12/sber-logo.png"
                />
              </div>
              <div>
                <img
                  class="uk-width-auto"
                  src="https://vevanta.ru/wp-content/uploads/2020/12/souz-logo.png"
                />
              </div>
              <div>
                <img
                  class="uk-width-auto"
                  src="https://vevanta.ru/wp-content/uploads/2020/12/bars-logo.png"
                />
              </div>
              <div>
                <img
                  class="uk-width-auto"
                  src="https://vevanta.ru/wp-content/uploads/2020/12/rossel-logo.png"
                />
              </div>
              <div>
                <img
                  class="uk-width-auto"
                  src="https://vevanta.ru/wp-content/uploads/2020/12/sngb-logo.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="ways-block ways-info ways-info--mobile uk-hidden@s uk-padding-small"
        >
          <p
            class="fs34 ways-info__title uk-text-uppercase uk-text-bold uk-margin-medium-bottom"
          >
            <span class="uk-position-relative">Способы покупки</span>
          </p>
          <div
            class="uk-flex uk-flex-middle uk-flex-center uk-margin-remove uk-flex-around"
          >
            <p
              class="ways-info__item fs26 uk-flex uk-flex-column uk-flex-center uk-flex-middle"
            >
              <img
                src="https://vevanta.ru/wp-content/uploads/2020/12/mortgage.png"
              /><span class="uk-position-relative">Ипотека</span>
            </p>
            <p
              class="ways-info__item fs26 uk-flex uk-flex-column uk-flex-center uk-flex-middle"
            >
              <img
                src="https://vevanta.ru/wp-content/uploads/2020/12/future.png"
              /><span class="uk-position-relative">Рассрочка</span>
            </p>
            <p
              class="ways-info__item fs26 uk-flex uk-flex-column uk-flex-center uk-flex-middle"
            >
              <img
                src="https://vevanta.ru/wp-content/uploads/2020/12/wallet.png"
              /><span class="uk-position-relative">Наличные</span>
            </p>
          </div>
        </div>
        <div
          class="uk-flex uk-flex-column uk-flex-center uk-flex-middle info__bottom"
        >
          <div class="info__content">
            <p class="uk-text-center fs18 lh1">
              Оставьте заявку на бесплатную консультацию ипотечного брокера,
              чтобы получить индивидуальный расчет
            </p>
            <div class="uk-flex uk-flex-wrap">
              <div
                class="info__broker broker uk-flex uk-flex-column uk-flex-middle uk-width-1-1 uk-width-2-5@m"
              >
                <img
                  src="https://vevanta.ru/wp-content/uploads/2020/10/Марина-Михно-брокер.jpg"
                  class="broker__photo"
                />
                <p class="uk-text-bold uk-margin-remove-bottom lh1">
                  Марина Михно
                </p>
                <p class="uk-margin-remove lh1">Ипотечный брокер</p>
              </div>
              <div
                class="broker__form uk-flex uk-flex-column uk-flex-middle uk-width-1-1 uk-width-3-5@m"
              >
                <input
                  class="uk-input uk-width-1-1 uk-margin-small-bottom"
                  type="text"
                  placeholder="Имя"
                  v-model="inputs.name"
                />
                <input
                  class="uk-input uk-width-1-1 uk-margin-small-bottom"
                  type="text"
                  placeholder="Телефон"
                  v-model="inputs.phone"
                />
                <button
                  class="btn btn__tpl1 btn__green uk-width-2-3 broker__btn btn__square"
                  @click="sendForm"
                >
                  Получить консультацию
                </button>
                <p
                  class="uk-margin-remove-bottom uk-margin-small-top fs10 uk-text-center"
                >
                  Отпарвляя заявку, вы соглашаетесь на обработку
                  <a
                    href="https://vevanta.ru/wp-content/themes/agentstvo/public/politika.pdf"
                    target="_blank"
                    >персональных данных</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="uk-width-1-1 uk-width-2-5@m ways-block uk-flex uk-flex-center uk-flex-middle uk-flex-column uk-visible@s"
      >
        <div class="ways-info">
          <p
            class="fs34 ways-info__title uk-text-uppercase uk-text-bold uk-margin-medium-bottom"
          >
            <span class="uk-position-relative">Способы покупки</span>
          </p>
          <div class="uk-flex ways-info__list">
            <p class="ways-info__item fs26">
              <img
                class="uk-width-auto uk-margin-small-right"
                src="https://vevanta.ru/wp-content/uploads/2020/12/mortgage.png"
              /><span class="uk-position-relative">Ипотека</span>
            </p>
            <p class="ways-info__item fs26">
              <img
                class="uk-width-auto uk-margin-small-right"
                src="https://vevanta.ru/wp-content/uploads/2020/12/future.png"
              /><span class="uk-position-relative">Рассрочка</span>
            </p>
            <p class="ways-info__item fs26">
              <img
                class="uk-width-auto uk-margin-small-right"
                src="https://vevanta.ru/wp-content/uploads/2020/12/wallet.png"
              /><span class="uk-position-relative">Наличные</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import input from "../mixins/inputMask";
import form from "../mixins/Form";

export default {
  mixins: [input, form],
  data() {
    return {
      name: null,
      phone: null
    };
  },
  created() {
    this.dataRequest.staffId = 48;
    this.dataRequest.sourceId = 50;
    this.formName = "Ипотека на приобретение земельного участка";
  },
  watch: {
    phone(val) {
      this.phoneMask(val);
    }
  }
};
</script>

<style scoped>
.ipoteka-container {
  padding: 60px 80px;
  width: 100%;
  background-image: url("https://vevanta.ru/wp-content/uploads/2020/12/ipoteka-back.png");
  background-repeat: no-repeat;
}

.ipoteka-container__info {
  z-index: 100;
  color: #132e3f;
}

.lh1 {
  line-height: 1;
}

.info__top {
  padding: 30px 40px 10px;
  background-color: rgba(255, 255, 255, 0.85);
}

.info__bottom {
  padding: 30px 40px;
  background-color: #fff;
}

.info__banks {
  gap: 20px;
}

.info__banks div {
  transition: .1s;
}
.info__banks div:hover {
  transform: scale(1.1);
}

.info__content {
  width: 80%;
}

.broker__photo {
  border-radius: 50%;
  height: 130px;
}

.broker__btn {
  font-size: 16px;
  line-height: 1.2;
}

.ways-block {
  background-color: #132e3f;
  color: #fff;
}

.ways-info__title span:after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.ways-info__item {
  cursor: pointer;
  position: relative;
  font-weight: normal;
}

.ways-info__item:hover {
  font-weight: bold;
}
.ways-info__item span::after {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  transition: 0.3s ease;
  transform: scaleX(0);
}

.ways-info__item:hover span::after {
  transform: scaleX(1);
}

.ways-info__list {
  flex-direction: column;
}

.ways-info--mobile .ways-info__title {
  font-size: 16px;
  text-align: center;
}
.ways-info--mobile .ways-info__list {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 90%;
}

.ways-info--mobile .ways-info__item {
  margin: 0px;
}
.ways-info--mobile .ways-info__item span {
  font-size: 14px;
}

@media screen and (max-width: 640px) {
  .ipoteka-container {
    padding: 5px;
    background-size: cover;
  }
  .info__top,
  .info__bottom {
    padding: 30px 20px;
  }
  .info__content {
    width: 100%;
  }
  .info__broker {
    margin-bottom: 15px;
  }
  .lh1 {
    line-height: 1.3;
  }
}
</style>
