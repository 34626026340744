<template>
  <div id="app">
    <header-component />
    <div class="uk-container-expand uk-background-default">
      <div class="uk-container uk-padding-small">

        <ul class="uk-breadcrumb vlife__bread uk-margin-medium-bottom">
          <li><a href="https://vevanta.com/">Загородная недвижимость</a></li>
          <li><a href="https://vevanta.com/zemelnye-uchastki">Коттеджные поселки</a></li>
          <li><span>Веванта Лайф</span></li>
        </ul>


        <div class="uk-flex uk-flex-wrap uk-flex-between">
          <h2>Коттеджный поселок &laquo;Веванта Лайф&raquo;</h2>
          <img src="./assets/img/vevlife.png" alt="">
        </div>
        <div class="uk-flex uk-flex-column uk-flex-middle vlife__top-div uk-light uk-padding-small uk-margin-small-top">
          <h1 class="uk-margin-xlarge-top uk-margin-small-bottom vlife__top-h1 uk-text-center">Участки в коттеджном поселке &laquo;Веванта Лайф&raquo;</h1>
          <ul class="uk-list fs22@m fs14 vlife__top-ul">
            <li class="uk-margin-medium-right uk-margin-small-bottom"><img src="./assets/icons/1_icon_top.png" alt=""> 10 минут до города</li>
            <li class="uk-margin-medium-right uk-margin-small-bottom"><img src="./assets/icons/2_icon_top.png" alt=""> Электричество, газ и дороги</li>
            <li class="uk-margin-medium-right uk-margin-small-bottom"><img src="./assets/icons/3_icon_top.png" alt=""> Детская площадка и воркаут зона</li>
            <li class="uk-margin-medium-right uk-margin-small-bottom"><img src="./assets/icons/4_icon_top.png" alt=""> Лес рядом</li>
          </ul>
          <a href="#map-component"><button class="btn btn__tpl1 btn__green btn__square uk-margin-large-top uk-margin-xlarge-bottom">Выбрать участок</button></a>
        </div>
        <a href="#map"><p class="fs18 uk-margin-small-top"><span uk-icon="icon: location; ratio: 1.2;"></span> Московский тракт, деревня Зубарева, 13 км до города</p></a>
        <h2 class="uk-margin-large-top">Описание</h2>
        <div class="uk-flex uk-flex-baseline uk-flex-wrap">
          <div class="uk-width-2-3@m uk-width-1-1@s">
            <p class="fs16">Веванта Лайф — новый коттеджный поселок для жизни за городом, который включает в себя 130 земельных участков площадью от 6 до 25 соток, с назначением земли под личное подсобное хозяйство (ЛПХ).<br>Главной особенностью коттеджного поселка является его месторасположение — КП «Веванта Лайф» расположился в удивительно красивом месте в окружении леса, при этом добраться от Тюмени до него можно всего за 7-10 минут на автомобиле.<br>Поселок находится на Московском тракте, рядом с деревней Зубарева, между селами Перевалово и Гусево.
            </p>
            <button class="uk-button uk-button-default uk-margin-medium-bottom btn__green btn__square">Документы <span uk-icon="chevron-down"></span> </button>
          </div>
          <div  class="uk-width-1-2@s uk-width-1-3@m uk-width-1-1 uk-padding-medium-left uk-flex uk-flex-center uk-flex-end@m">
            <iframe width="90%" height="200" src="https://www.youtube.com/embed/jdTEBR7WxTw" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>

        <consultation
          :staff-id="80" :source-id="50"
          picture="https://cloud.vevanta.ru/photo/users/44/b0c0e83744a31e9bf03aa95bede12fef.jpg"
          name="Владислав Лончаков" position="руководитель проекта"
          title="Задайте вопрос руководителю проекта"
          phone="9220739201"
          btnName="Перезвоните мне"
        />


        <h2 class="uk-margin-large-top uk-flex uk-flex-middle" id="map-component">
          Выберите участок в коттеджном поселке &laquo;Веванта Лайф&raquo;<a href="#" uk-totop uk-scroll></a>
        </h2>

        <!-- карта -->
        <div class="uk-flex uk-flex-center vlife__map-div">
          <developer-map />
        </div>
        <!-- карта -->

        <div class="uk-margin-medium-top">
          <ipotekaForm />
        </div>

      </div>



      <div class="uk-container uk-padding-small">
        <common
          title="Записаться на просмотр"
          subtitle="Проведем для вас экскурсию по Веванта Лайф"
          picture="https://vevanta.ru/wp-content/uploads/2020/12/car.png"
          btnName="Записаться"
          :staff-id="80"
          :source-id="50"
        />

        <h2 class="uk-margin-large-top">Преимущества коттеджного поселка &laquo;Веванта Лайф&raquo;</h2>
        <div class="uk-flex uk-flex-start uk-flex-wrap fs22 uk-child-width-1-5@m uk-child-width-1-3@s uk-child-width-1-2 uk-text-center uk-margin-large-top uk-margin-large-bottom">
          <div><div><img src="./assets/icons/advantages/1_gas.png"></div><p>Газ</p></div>
          <div><div><img src="./assets/icons/advantages/2_elec.png"></div><p>Электричество</p></div>
          <div><div><img src="./assets/icons/advantages/3_roads.png"></div><p>Дороги</p></div>
          <div><div><img src="./assets/icons/advantages/4_schlag.png"></div><p>Шлагбаум</p></div>
          <div><div><img src="./assets/icons/advantages/5_kids.png"></div><p>Детская площадка</p></div>
          <div><div><img src="./assets/icons/advantages/6_workout.png"></div><p>Зона воркаут</p></div>
          <div><div><img src="./assets/icons/advantages/7_infra.png"></div><p>Развитая инфраструктура</p></div>
          <div><div><img src="./assets/icons/advantages/8_10min.png"></div><p>10 минут до города</p></div>
          <div><div><img src="./assets/icons/advantages/9_eco.png"></div><p>Экологичный район</p></div>
          <div><div><img src="./assets/icons/advantages/10_forest.png"></div><p>Лес рядом</p></div>
        </div>

        <div uk-slider>
          <div class="uk-position-relative" tabindex="-1">

              <ul class="uk-slider-items uk-child-width-1-1 uk-dark">
                  <li v-for="picture in pictures" :key="picture">
                      <img :src="`${cdn}vlife-gal-${picture}.jpg`" alt="">
                      <div class="uk-position-bottom-right uk-margin-medium-right uk-margin-medium-bottom">
                        <img src="./assets/img/watermark.png" alt="">
                      </div>
                  </li>
              </ul>

              <a class="uk-position-center-left uk-position-small uk-slidenav-large" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
              <a class="uk-position-center-right uk-position-small uk-slidenav-large" href="#" uk-slidenav-next uk-slider-item="next"></a>
          </div>
        </div>

        <div uk-slider class="uk-margin-medium-top">
            <div class="uk-position-relative">
                <div class="uk-slider-container uk-light">
                    <ul class="uk-slider-items uk-child-width-1-3 uk-grid">
                        <li v-for="picture in pictures" :key="picture">
                          <img :src="`${cdn}vlife-gal-${picture}.jpg`" alt="">
                          <div class="uk-position-bottom-right uk-margin-small-right uk-margin-small-bottom uk-visible@m">
                            <img src="./assets/img/watermark.png" alt="" width="50">
                          </div>
                        </li>
                    </ul>
                </div>
                <div class="uk-hidden@s uk-light">
                    <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                    <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
                </div>
                <div class="uk-visible@s">
                    <a class="uk-position-center-left-out uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                    <a class="uk-position-center-right-out uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
                </div>
            </div>
        </div>

        <h2 class="uk-margin-large-top">Инфраструктура</h2>
        <p class="fs16">
          Рядом с поселком уже развита вся необходимая инфраструктура: школа, детский сад, магазины и супермаркеты, досуговые центры, медицинский и почтовый пункты. Поселок окружен лесом, грибными и ягодными местами.
        </p>
        <infrastructure />
      </div>

    </div>

    <div class="uk-container-expand uk-background-default uk-padding-medium">
      <div class="uk-container uk-padding-small">

        <h2 class="uk-margin-large-top">Приобрести участок с домом</h2>
        <p class="fs16">
          Приобретая участок, вы можете заказать постройку дома от строительной компании «Веванта». «Веванта» строит дома по готовым и индивидуальным проектам уже более 9 лет. Для всех, кто приобрел земельные участки в коттеджном поселке «Веванта Лайф», предусмотрены специальные условия на строительство домов от строительной компании «Веванта»!
        </p>
        <life-houses />
        <p class="fs16">
          Каждый, кто приобрел земельный участок в коттеджном поселке "Веванта Лайф" и заключил договор на строительство дома со строительной компанией "Веванта", получает подарок на выбор:
        </p>
        <ul class="vlife_presents-ul uk-margin-large-bottom">
          <li class="uk-flex uk-flex-middle"><span class="uk-margin-small-right"><img src="./assets/icons/present.png" width="40px" height="40px"></span>обустройство въезда на участок</li>
          <li class="uk-flex uk-flex-middle uk-margin-small-top"><span class="uk-margin-small-right"><img src="./assets/icons/present.png" width="40px" height="40px"></span>50 000 рублей - скидка на возведение коробки дома</li>
        </ul>

        <common :staff-id="78" :source-id="50" />

      </div>
    </div>

    <footer-component></footer-component>
  </div>
</template>

<script>
import HeaderComponent from "./components/Header";
import FooterComponent from "./components/Footer";
import Consultation from "./components/Consultation";
import LifeHouses from "./components/lifeHouses";
import Common from "./components/Common"
import DeveloperMap from "./components/DeveloperMap";
import Infrastructure from "./components/Infrastructure";
import ipotekaForm from "./components/ipotekaForm";

export default {
  name: "App",
  components: {
    HeaderComponent,
    FooterComponent,
    Consultation,
    LifeHouses,
    Common,
    DeveloperMap,
    Infrastructure,
    ipotekaForm,
  },

  data() {
    return {
      cdn: "https://vevanta.ru/wp-content/uploads/2021/02/",
      pictures: 32
    }
  }
};



</script>

<style>
@import "assets/fonts.min.css";
@import "assets/styles/main.min.css";

h1, h2 {
  font-size: 1.7rem;
  font-family: Gilroy-Regular;
  font-weight: 600;
  text-transform: none;
}

h1 {
  font-weight: bold;
}

.uk-navbar-nav > li > a {
  min-height: 30px;
}

.btn__green {
  border-radius: 21px/50%;
  border: 2px solid #27beb8;
  font-size: 0.875rem;
  background: #27beb8;
  color: #fff;
  text-transform: uppercase;
  padding: 6px 14px;
  cursor: pointer;
  font-weight: 400;
  font-family: gilroy-medium, sans-serif;
}
.btn__green:hover {
  background: #fff;
  color: #27beb8;
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.green,
.green:hover {
  color: #27beb8;
}

.vlife__bread > * > * {
  font-size: 1rem;
}

.vlife__bread > :nth-child(n+2):not(.uk-first-column)::before{
  margin: 0;
}
/*17/12/2020*/
.border__grey {
  border: 1px solid lightgrey;
  margin-right: 5px;
}

.btn__square {
  border-radius: 0;
}

.uk-list-bullet > li {
  margin-left: 1em;
}

.uk-list-bullet > li::before {
  background-image: url(./assets/icons/marker.png);
  position: absolute;
  left: -10px;
}

.vlife__nocolor {
  color: inherit;
}

.vlife__top-div {
  background-image: url(./assets/img/head.jpg);
  background-size: cover;
  text-shadow: 1px 1px 2px black, 0 0 1em #1A2E45;
}

.vlife__top-ul {
  font-weight: bolder;
  color: white;
}

.vlife__map-div {
  max-width: 100%;
}

.vlife_presents-ul {
  list-style-type: none;
}

.vlife__top-h1 {
  font-size: 40px;
  font-weight: bolder;
}

.uk-container {
  max-width: 1200px;
}

.mortgage-div {
  background: url(./assets/img/bg-mortgage.jpg) top left no-repeat;
}

.no-padding {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 580px) {
  .vlife__top-h1 {
    font-size: 26px;
  }

  .mortgage-div {
    background-size: cover;
  }
}

</style>
