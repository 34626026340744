<template>
  <div>

    <div class="uk-container uk-margin-small">
      <div
        class="uk-grid uk-flex-around uk-margin-medium-top uk-text-center uk-text-left@m uk-padding-small"
      >
        <div class="uk-width-1-1 uk-width-auto@l uk-margin-bottom">
          <a href="https://vevanta.com"
            ><img class="uk-align-center" src="../assets/img/logo.png"
          /></a>
        </div>
        <div class="uk-width-1-1 uk-width-expand@l uk-margin-bottom ">
          ©2009-{{new Date().getFullYear()}} Компания «Веванта». Все права защищены. При использовании
          материалов гиперссылка обязательна.
        </div>
        <div class="uk-width-1-1 uk-width-1-4@l uk-margin-bottom">
          г.Тюмень, ул. Червишевский тракт, д. 3<br />
          <a href="tel:+7 (3452) 21-59-71" class="green">+7 (3452) 21-59-71</a>
        </div>
        <div>
          <div class="uk-margin-small-bottom">
            Социальные сети
          </div>
          <div class="uk-margin-small-bottom uk-text-center">
            <a
              href="https://vk.com/stroitelstvo_domov_i_kottedjey"
              class="uk-margin-small-right"
              target="_blank"
            >
              <img src="../assets/img/vkontakte.png" height="20px" width="20px"
            /></a>
            <a
              href="https://www.instagram.com/vevanta72"
              class="uk-margin-small-right"
              target="_blank"
              ><img src="../assets/img/instagram.png" height="20px" width="20px"
            /></a>
            <a
              href="https://www.youtube.com/channel/UCRytU0cHs-IZN0dsBZW6Shw"
              class="uk-margin-small-right"
              ><img src="../assets/img/youtube.png" height="20px" width="20px"
            /></a>
          </div>
        </div>
      </div>

      <div class="uk-text-center ">
        <a
          href="https://vevanta.com/politika-konfidencialnosti"
          class="green"
          target="_blank"
          >Политика конфиденциальности</a
        >
      </div>
    </div>
  </div>
</template>

<script>

export default {

  data() {
    return {
      agency: "",
      phone: "",
      email: "",
      isValid: true,
    };
  },
  watch: {
    phone(val) {
      this.phoneMask(val, "phone");
    }
  },
  computed: {
    validForm() {
      return !(!this.email || !this.agency || !this.phone);
    },
  },
  methods: {
    subscribe() {
      if (!this.validForm) {
        this.isValid = false;
        return;
      }
      this.isValid = true;
      this.$axios
        .post("mail/vevanta_partner", {
          form: "Подписка на рассылку Веванта партнеры",
          name: this.agency,
          phone: this.phone,
          email: this.email,
          msg: "Подписка на рассылку Веванта партнеры<br>",
          source_id: 13,
          assigned_by_id: 78,
        })
        .then(() => {
          this.UIkit.modal("#success-modal").show();
          this.name = "";
          this.phone = "";
          this.email = "";
        });
    },
  },
};
</script>

<style>
.footer-background {
  background-image: url("../assets/img/footer.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.footer__form-conatin {
  background-color: #fff;
}

.footer__footer-title {
  font-size: 23px;
}
</style>
