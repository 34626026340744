<template>
  <div :id="modalId" uk-modal ref="modal_call">
    <div class="uk-modal-dialog uk-modal-body uk-width-1-1@s uk-width-1-4@m">
      <h5 class="uk-modal-title uk-margin-remove ">
        {{title}}
      </h5>
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <form class="uk-margin-small-top" @submit.prevent="send">
        <div v-if="isSent" class="uk-alert-success" uk-alert>
          <p>
            Заявка успешно отправлена. В ближайшее время с вами свяжется наш
            менеджер
          </p>
        </div>
        <div v-else>
          <div>
            <div>
              <label class="uk-form-label" for="name">ФИО</label>
              <div class="uk-form-controls">
                <input
                  class="uk-input"
                  v-model="inputs.name"
                  id="name"
                  type="text"
                  placeholder="ФИО"
                />
              </div>
            </div>
            <div>
              <label class="uk-form-label" for="phone">Телефон</label>
              <div class="uk-form-controls">
                <input
                  class="uk-input"
                  v-model="inputs.phone"
                  id="phone"
                  type="tel"
                  placeholder="(999) 999-99-99"
                />
              </div>
            </div>
          </div>
          <div class="politica">
            Отправляя заявку, вы соглашаетесь на обработку
            <a :href="politika" target="_blank">персональных данных</a>
          </div>

          <div class="uk-width-1-1 uk-text-right uk-flex uk-flex-center uk-margin-small-top">
            <button class="btn btn__tpl1 btn__green btn__square">
              {{btnName}}
            </button>
          </div>
        </div>
      </form>


    </div>
  </div>
</template>

<script>
  import form from "../mixins/Form.js";
  import input from "../filters/Input.js";
  import inputMask from "../mixins/inputMask";

  export default {
    name: 'ModalCallback',
    mixins: [form, input, inputMask],

    props: {

      modalId:{
        type: String
      },
      btnName: {
        type: String
      },
      title: {
        type: String,
        default: "Консультируем бесплатно",
      },
      subtitle: {
        type: String,
        default: "Оставьте заявку или звоните нам по телефону:",
      },
      politika: {
        type: String,
        default: "/wp-content/themes/agentstvo/public/politika.pdf",
      },
      staffId: {
        type: Number,
      },
      managerId: {
        type: Number,
      },
      classId: {
        type: Number,
      },
      sourceId: {
        type: Number,
      },
    },

    methods: {
      send() {
        this.formName = this.title;

        if (this.staffId !== undefined) {
          this.dataRequest.staffId = this.staffId;
        }

        if (this.managerId !== undefined) {
          this.dataRequest.managerId = this.managerId;
        }

        if (this.classId !== undefined) {
          this.dataRequest.classId = this.classId;
        }

        if (this.sourceId !== undefined) {
          this.dataRequest.sourceId = this.sourceId;
        }

        this.sendForm();
      },
    }

  }
</script>
